<template>
  <Nav></Nav>
  {{ navCollapseShow }}

  <router-view />
</template>

<script>
import { onMounted } from "vue";

import Nav from "../src/components/Nav";
export default {
  name: "App",
  components: { Nav },
  setup() {
    function DoNavClick() {
      //alert("t");
    }

    onMounted(() => {});

    return { DoNavClick };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 20px;
}

.navbar-light .navbar-nav .nav-link {
  color: #007bff;
}
</style>
