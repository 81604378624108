<template>
  <div class="row">
    <div class="col-md-12">
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <a class="navbar-brand" href="/"
          ><img src="/images/Larm.jpg" style="height: 40px"
        /></a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="collapse navbar-collapse"
          id="navbarNavDropdown"
          v-bind:class="{ show: navCollapseShow }"
        >
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link to="/" @click="tab = 1" class="nav-link"
                >首頁</router-link
              >
            </li>

            <li class="nav-item">
              <router-link to="/Larm" @click="tab = 2" class="nav-link"
                >廣論</router-link
              >
            </li>

            <li class="nav-item">
              <router-link to="/NSL" @click="tab = 3" class="nav-link"
                >南山律</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/Moon" @click="tab = 4" class="nav-link"
                >廣海明月</router-link
              >
            </li>
            <li class="nav-item" v-if="CheckIsAdm()">
              <router-link to="/MoonList" @click="tab = 6" class="nav-link"
                >廣海明月(編輯)</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/Shamatha" @click="tab = 5" class="nav-link"
                >止觀初探</router-link
              >
            </li>
            <li class="nav-item" v-if="CheckIsAdm()">
              <router-link to="/ShamathaList" @click="tab = 5" class="nav-link"
                >止觀初探(編輯)</router-link
              >
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import IsAdmin from "@/composables/Line/CheckAdm";
export default {
  name: "Nav",
  setup() {
    const router = useRouter();
    let tab = ref("");
    const NavRoute = ref(router.currentRoute.value);
    const Nowroute = useRoute();
    let IsUserAdm = ref(0);

    function CheckIsAdm() {
      console.log("IsAdmin():", IsAdmin());
      return IsAdmin() == 1;
    }

    onMounted(() => {
      console.log("URL:", router.currentRoute.value);
      console.log("Path:", Nowroute.path);
      IsUserAdm = IsAdmin();
      console.log("Nav check is User Admin?", IsUserAdm.value);
    });

    return { NavRoute, tab, IsUserAdm, CheckIsAdm };
  },
};
</script>

<style>
</style>