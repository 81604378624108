import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import IsAdmin from "@/composables/Line/CheckAdm";

let routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/index',
        name: 'Index',
        component: () => import('../views/Index.vue')
    },
    {
        path: '/Test',
        name: 'Test',
        component: () => import('../views/Test.vue')
    },
    {
        path: '/Larm',
        name: 'Larm',
        component: () => import('../views/Larm.vue')
    },

    {
        path: '/NSL',
        name: 'NSL',
        component: () => import('../views/NSL.vue')
    },
    {
        path: '/Moon',
        name: 'Moon',
        component: () => import('../views/Moon.vue')
    },
    {
        path: '/Shamatha',
        name: 'Shamatha',
        component: () => import('../views/Shamatha.vue')
    },
    {
        path: '/35F',
        name: '35F',
        component: () => import('../views/35F.vue')
    },
    {
        path: '/Golden',
        name: 'Golden',
        component: () => import('../views/Golden.vue')
    },
    {
        path: '/MoonEdit',
        name: 'MoonEdit',
        component: () => import('../views/MoonEdit.vue')
    },
    {
        path: '/LineTest',
        name: 'LineTest',
        component: () => import('../views/LineTest.vue')
    },
    {
        path: '/ShamathaList',
        name: 'ShamathaList',
        component: () => import('../views/ShamathaList.vue')
    },
    {
        path: '/hi',
        name: 'hi',
        component: () => import('../views/hi.vue')
    },
    {
        path: '/TestTree',
        name: 'TestTree',
        component: () => import('../views/TestTree.vue')
    },
    {
        path: '/GoodBehavior',
        name: 'GoodBehavior',
        component: () => import('../views/GoodBehavior.vue')
    }

]

console.log('check route:', routes);
console.log('Line User ID:', localStorage.getItem("user_subid"));

if (IsAdmin() == 1) {
    let vMoonList = {
        path: '/MoonList',
        name: 'MoonList',
        component: () => import('../views/MoonList.vue')
    }
    routes.push(vMoonList)

    console.log('After add adm', routes);
}

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
