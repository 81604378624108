<template>
  <div
    id="carouselExampleControls"
    class="col-md-12 mb-5 carousel slide"
    data-ride="carousel"
  >
    <div class="carousel-inner">
      <div
        class="carousel-item"
        :class="{ active: index == 0 }"
        v-for="(n, index) in basedata.YStoryData"
        :key="index"
      >
        <img
          :src="n.YPic"
          class="d-block w-100"
          :alt="n.Describe"
          @click="DoClick(n.YUrl)"
        />
      </div>
    </div>
    <a
      class="carousel-control-prev"
      href="#carouselExampleControls"
      role="button"
      data-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a
      class="carousel-control-next"
      href="#carouselExampleControls"
      role="button"
      data-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</template>

<script>
import { reactive } from "vue";
export default {
  setup() {
    const basedata = reactive({
      YStoryData: [
        {
          Title: "2021憶師恩法會[前行]",
          Describe: "阿吉的善行日記",
          YUrl: "https://youtu.be/MYdhcZuzsKY",
          YPic: "/images/YImg/20211026.jpg",
        },
        {
          Title: "福智真如老師為臺灣地震開示：皈依三寶，解脫災難怖畏",
          Describe: "皈依三寶，解脫災難怖畏",
          YUrl: "https://youtu.be/hClIX3ol_ww",
          YPic: "/images/YImg/20201214.jpg",
        },
        {
          Title: "解析十二因緣 用什麼脫離感受的擺布",
          Describe: "用什麼脫離感受的擺布",
          YUrl: "https://youtu.be/9gU6J0gYU4Q",
          YPic: "/images/YImg/2020120903.jpg",
        },
      ],
    });

    function DoClick(vUrl) {
      window.open(vUrl);
    }

    return { basedata, DoClick };
  },
};
</script>

<style>
</style>