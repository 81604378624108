<template>
  <div class="home">
    <section>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <gcse:search v-pre></gcse:search>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container">
        <div class="row">
          <YStory></YStory>
          <div class="col-md-6 d-none d-sm-block mb-5">
            <a href="/35F"><img src="/images/AllF.jpg" width="100%" /></a>
          </div>
          <div class="col-md-6 d-sm-none mb-1">
            <a href="/35F"><img src="/images/AllFS.jpg" width="100%" /></a>
          </div>
          <div class="col-md-6 text-left mb-5">
            <h1>35佛名號</h1>
            <p>在《三十五佛懺》裡有講到每一尊佛號它能夠滅除的重罪。</p>

            <p>
              如果以這樣的力度來念佛的話，我們有命存活的念念中，珍惜時光，我們可以消多少罪業，同時又對佛陀所講的所有法門都保持著恭敬隨學的心；對於修學不同法門的善知識或者僧眾還有俗眾，都保持著恭敬心，真的像遍地黃金一樣，我們可以累積相當多的資糧！
            </p>

            <p><a href="/35F">→連結</a></p>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container">
        <div class="row">
          <div class="col-md-6 d-none d-sm-block mb-5">
            <a href="/Golden"><img src="/images/golden.png" width="100%" /></a>
          </div>
          <div class="col-md-6 d-sm-none mb-1">
            <a href="/Golden"
              ><img src="/images/golden_s.png" width="100%"
            /></a>
          </div>
          <div class="col-md-6 text-left mb-5">
            <h1>金光明最勝王經</h1>
            <p>
              《金光明最勝王經》梵文為Suvarna-prabhasa是眾經之王，在開闡如來秘密心髓、懺悔業障、集聚資糧以及弘揚聖教、護國利民等方面具有殊勝的功德，在蒙藏地區也持誦的非常普遍，過去在蒙族地區有各家輪流供養僧人每天念誦《金光明最勝王經》的習俗。尊宗喀巴大師在《菩提道次第廣論》依四力懺悔部分中指導大家修「能破壞現行」力時，也強調【應由勝金光明懺及三十五佛懺】二種悔除。
            </p>

            <p><a href="/Golden">→連結</a></p>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container">
        <div class="row">
          <div class="col-md-6 mb-5">
            <h1>廣論科判</h1>
            <p>廣論科判連結</p>
            <p>
              <a target="_empty" href="larmtree.html">→連結</a>
            </p>
            <p>
              <a target="_empty" href="larmtree.html"
                ><img src="/images/larmtree.jpg" width="100%"
              /></a>
            </p>
          </div>
          <div class="col-md-6 mb-5">
            <h1>備覽科判</h1>
            <p>備覽科判連結</p>
            <p>
              <a target="_empty" href="nancategory.html">→連結</a>
            </p>
            <p>
              <a target="_empty" href="nancategory.html"
                ><img src="/images/nantree.jpg" width="100%"
              /></a>
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import YStory from "../components/YStory.vue";
export default {
  name: "Home",
  components: { YStory },
  mounted() {
    var cx = "002047481276774726341:uqalit40df4";
    var gcse = document.createElement("script");
    gcse.type = "text/javascript";
    gcse.async = true;
    gcse.src = "https://cse.google.com/cse.js?cx=" + cx;
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(gcse, s);
  },
};
</script>
